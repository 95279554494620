import { Collapse, TextField, FormControl, Autocomplete, Select, MenuItem, InputLabel } from "@mui/material";
import { useState, useEffect } from 'react';
import { register } from '../actions/authActions';
import { ProtectAdminRoute } from "../manageRoutes/ProtectRoutes";
import AdminNavBar from "../compoents/AdminNavBar";
import { getAllCourses } from "../actions/courseActions";

const RegisterUser = () => {

  const [courses, setAllCourses] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSubject, setSelectedSubject] = useState([])
  const [serverResponse, setServerResponse] = useState(["", ""]);
  const [openForm, setOpenForm] = useState(true)
  const [fieldErrors, setFieldErrors] = useState({
    courseName: "",
    semester: "",
    subject: "",
    type: "",
    batch: "",
  });
  const [studentData, setStudentData] = useState({
    name: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    role: 0,
    semester: "",
    subjects: [],
    batch: "",
    course: "",
    seatNumber: "",
    error: "",
    errorMessage: ""
  })
  const { firstName, middleName, lastName, email, semester, seatNumber, subjects, batch, error, errorMessage } = studentData

  useEffect(() => {
    const fetchCourses = async () => {
      const res = await getAllCourses()
      setAllCourses(res.courses.reverse())
    }
    fetchCourses()
  }, []);

  useEffect(() => {
    setAvailableCourses([
      ...new Set(courses.map((subject) => subject.courseName)),
    ]);
  }, [courses]);

  useEffect(() => {
    if (serverResponse[0] === "true") {
      /*removeCard()
      setStudentData({
        name: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        role: 0,
        semester: "",
        subjects: [],
        batch: "",
        course: "",
        seatNumber: "",
        error: "",
        errorMessage: ""
      })*/
    }
    setTimeout(() => { setServerResponse(["", ""]) }, 10000)
  }, [serverResponse])

  const addToSelectQuery = () => {
    const newErrors = {
      courseName: !selectedCourse ? "Course Name is required." : "",
      semester: !semester ? "Semester is required." : "",
      batch: !batch ? "Batch is required." : "",
    };

    if (Object.values(newErrors).some((error) => error !== "")) {
      setFieldErrors(newErrors);
      return;
    }

    const subjectsOfCourse = [...new Set(
      courses
        .filter(course => course.courseName === selectedCourse && course.semester === semester)
        .flatMap(course => course.subjects)
    )];

    setSelectedSubject(subjectsOfCourse)
    setFieldErrors({
      courseName: "",
      semester: "",
      subject: "",
      type: "",
      batch: "",
    });
  };

  const handleStudentChange = (e) => {
    setStudentData(prevData => ({ ...prevData, error: "", errorMessage: "", [e.target.name]: e.target.value }))
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validateSeatNumber = (seatNumber) => {
    const seatNumRegex = /^\d{11}$/;
    return seatNumRegex.test(seatNumber)
  }

  const validateData = () => {
    if (firstName === "" || middleName === "" || lastName === "" || email === "") {

      setStudentData((prevData) => ({
        ...prevData,
        error: "userForm",
        errorMessage: "Field Required",
      }));
      return;

    }
    if (!validateEmail(email)) {

      setStudentData((prevData) => ({
        ...prevData,
        error: "userFormEmail",
        errorMessage:
          "Invalid email format. Please enter a valid email address.",
      }));
      return;

    }
    if (!validateSeatNumber(seatNumber)) {

      setStudentData((prevData) => ({
        ...prevData,
        error: "userFormSeatNumber",
        errorMessage:
          "Invalid Seat Number. Please enter valid seat number.",
      }));
      return;

    } else {
      setOpenForm(false);
    }
  }
  const handleRegistration = async (e) => {
    e.preventDefault();
    setStudentData((prevData) => ({ ...prevData, course: selectedCourse, name: `${firstName} ${middleName} ${lastName}`, subjects: selectedSubject }))

    let Student = { ...studentData, course: selectedCourse, name: `${firstName} ${middleName} ${lastName}`, subjects: selectedSubject }

    if (firstName === "" || email === "") {
      setStudentData((prevData) => ({
        ...prevData,
        error: "All fields are required ! ",
      }));
      return
    }
    if (!validateEmail(email)) {
      setStudentData((prevData) => ({
        ...prevData,
        error: "Invalid email format. Please enter a valid email address.",
      }));
    } else {
      try {
        const res = await register(Student)
        const user = await res.json()
        if (user.status === false || user.success === false) {
          console.log("user2", user)
          setServerResponse(["false", user.message])
        } else {
          setServerResponse(["true", "User Created Successfully"])
        }
      } catch (error) {
        setServerResponse(["false", "An unexpected error occurred. Please try again"])
      }
    }
  };

  const removeCard = () => {
    setStudentData((prevData) => ({
      ...prevData,
      semester: 0,
      subjects: [],
      batch: "",
      course: "",
      error: "",
      errorMessage: ""
    }))
    setSelectedCourse("");
    setSelectedSubject([])
  }
  return (
    <ProtectAdminRoute>
      <AdminNavBar />
      <div className="container-fluid d-flex flex-wrap justify-content-around py-5">
        <div
          className="col-md-5 col-10 py-5 d-flex justify-content-center align-items-start sticky-md-top"
          style={{ height: "100%" }}
        >
          <div className="col-12">
            <h2 className="text-danger text-center">User Registration</h2>
            <div>
              <Collapse in={openForm}>
                <div className="py-2">
                  <div className="mb-3">
                    <TextField
                      className="col-12"
                      error={error === "userForm" ? true : false}
                      value={firstName}
                      label={
                        error === "userForm"
                          ? `${errorMessage} First Name`
                          : "First Name"
                      }
                      name="firstName"
                      onChange={(e) => handleStudentChange(e)}
                    />
                  </div>

                  <div className="mb-3">
                    <TextField
                      className="col-12"
                      error={error === "userForm" ? true : false}
                      label={
                        error === "userForm"
                          ? `${errorMessage} Middle Name`
                          : "Middle Name"
                      }
                      value={middleName}
                      name="middleName"
                      onChange={(e) => handleStudentChange(e)}
                    />
                  </div>

                  <div className="mb-3">
                    <TextField
                      className="col-12"
                      error={error === "userForm" ? true : false}
                      label={
                        error === "userForm"
                          ? `${errorMessage} Last Name`
                          : "Last Name"
                      }
                      value={lastName}
                      name="lastName"
                      onChange={(e) => handleStudentChange(e)}
                    />
                  </div>

                  <div className="mb-3">
                    <TextField
                      className="col-12"
                      error={error === "userForm" || error === "userFormSeatNumber" ? true : false}
                      label={
                        error === "userForm" || error === "userFormSeatNumber"
                          ? `${errorMessage} Seat Number`
                          : "Seat Number"
                      }
                      value={seatNumber}
                      name="seatNumber"
                      onChange={(e) => handleStudentChange(e)}
                    />
                  </div>

                  <div className="mb-3">
                    <TextField
                      type="email"
                      className="col-12"
                      error={error === "userForm" || error === "userFormEmail" ? true : false}
                      label={
                        error === "userForm" || error === "userFormEmail"
                          ? `${errorMessage} Email`
                          : "Email Id"
                      }
                      value={email}
                      name="email"
                      onChange={(e) => handleStudentChange(e)}
                    />
                  </div>

                  <div className="py-3">
                    <button
                      className="btn col-12 btn-outline-danger"
                      onClick={validateData}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Collapse>

              <Collapse in={!openForm}>
                <div className="py-2">
                  <div className="py-3">
                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => setOpenForm(true)}
                    >
                      Go Back
                    </button>
                  </div>

                  <div className="mb-3">
                    <FormControl fullWidth>
                      <div className="mb-3">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={["", ...availableCourses]}
                          value={selectedCourse}
                          onChange={(event, newValue) => {
                            setSelectedCourse(
                              newValue === "" ? null : newValue
                            );
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Course" />
                          )}
                        />
                        {fieldErrors.courseName && (
                          <div style={{ color: "red" }}>
                            {fieldErrors.courseName}
                          </div>
                        )}
                      </div>
                    </FormControl>
                  </div>

                  <div className="mb-3">
                    <FormControl fullWidth>
                      <InputLabel>Select Semester</InputLabel>
                      <Select
                        name="semester"
                        value={semester}
                        label="Select Semester"
                        onChange={(e) => {
                          handleStudentChange(e)
                        }}
                      >
                        <MenuItem value="">All Semesters</MenuItem>
                        {[
                          ...new Set(
                            courses
                              .filter((el) => el.courseName === selectedCourse)
                              .map((el) => el.semester)
                          ),
                        ].map((sem) => (
                          <MenuItem key={sem} value={sem}>
                            {sem}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldErrors.semester && (
                        <div style={{ color: "red" }}>
                          {fieldErrors.semester}
                        </div>
                      )}
                    </FormControl>
                  </div>

                  <div className="mb-3">
                    <FormControl fullWidth>
                      <InputLabel>Select Batch</InputLabel>
                      <Select
                        name="batch"
                        value={batch}
                        label="Select Batch"
                        onChange={(e) => handleStudentChange(e)}
                      >
                        <MenuItem value="">All Batches</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                      </Select>
                      {fieldErrors.batch && (
                        <div style={{ color: "red" }}>{fieldErrors.batch}</div>
                      )}
                    </FormControl>
                  </div>




                  <div className="d-grid gap-2 text-center">
                    <button
                      type="button"
                      className="btn btn-danger px-4 text-center"
                      onClick={addToSelectQuery}
                    >
                      Get Program Details
                    </button>
                  </div>

                  <hr />

                  <div className="d-grid gap-2 text-center">
                    <button disabled={selectedSubject.length === 0 ? true : false} className="btn btn-danger px-4 text-center" type="submit" onClick={(e) => handleRegistration(e)}>
                      Register User
                    </button>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="col-md-5 col-10 d-flex justify-content-center align-items-center">
          <div className="col-12 p-2 fs-5">
            <p>
              <span className="fw-bold">Name: </span>
              {firstName} {middleName} {lastName}
            </p>
            <p>
              <span className="fw-bold">Seat Number: </span>
              {seatNumber}
            </p>
            <p>
              <span className="fw-bold">Email: </span>
              {email}
            </p>
            {serverResponse[0] === "false" && (<div className="alert alert-danger" role="alert">{serverResponse[1]}</div>)}
            {serverResponse[0] === "true" && (<div className="alert alert-success" role="alert">{serverResponse[1]}</div>)}
            <div>
              {!selectedCourse ? (<div className="alert alert-info" role="alert">No Data</div>) :
                (
                  <div>
                    <div
                      className="card my-2 bg-transparent border-primary p-2"
                    >
                      <div className="d-flex flex-wrap justify-content-end">
                        <button
                          className="btn btn-danger btn-sm col-2"
                          onClick={removeCard}
                        >
                          Remove
                        </button>
                      </div>
                      <div className="">
                        <div>
                          <span className="fw-bold">Course: </span>
                          {selectedCourse}
                        </div>
                        <div>
                          <span className="fw-bold">Semester: </span>
                          {semester}
                        </div>
                        <div>
                          <span className="fw-bold">Subjects: </span>
                          {[...selectedSubject].join(", ")}
                        </div>
                        <div>
                          <span className="fw-bold">Batch: </span>
                          {batch}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </ProtectAdminRoute>
  )
}

export default RegisterUser;